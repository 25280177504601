<template>
<div class="tv">
  <div
      class="tv-img"
      :style="bgImg"
  >
    <h2
        class="title"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="500"
    >{{title}}</h2>
  </div>
  <ProductComponent
      :categories="categoryData"
      v-if="title === 'TV'"
  />
  <SubComponentProduct
      v-if="title !== 'TV'"
      :categories="subProductData"
  />
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
import ProductComponent from "@/components/ProductComponent";
import SubComponentProduct from "@/components/SubComponentProduct";
export default {
  name: "Tv",
  components: {SubComponentProduct, ProductComponent, Footer},
  computed: {

  },
  watch:{

  },
  data(){
    return{
      title: 'TV',
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/img/tv-bg.png')})`
      },
      subProductData: [],
      categoryData: [
        {
          name: 'UHD TV',
          subcategory: ['UHD 8K Smart TV','UHD 4K Smart TV']
        },
        {
          name: 'HD TV',
          subcategory: ['FHD Smart TV', 'FHD Digital TV', 'HD Smart TV', 'HD Digital TV']
        },
        {
          name: 'TV Mounts',
          subcategory: ['Fixed Wall Mounts', 'Tilting Wall Mounts', 'Full Motion Wall Mounts']
        }
      ]
    }
  },
  methods: {

  },
  mounted() {
    window.scrollTo(0,0)
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.tv{
  .tv-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    padding: 414px 0 77px 100px;
    .title{
      color: $white;
    }
  }
}
</style>
